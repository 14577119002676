import React from 'react'

import PropTypes from 'prop-types'

import './bottom.css'

const Bottom = (props) => {
  return (
    <header className={`bottom-container ${props.rootClassName} `}>
      <img
        alt={props.imageAlt1}
        src="/external/logo-200h-200h.png"
        className="bottom-image"
      />
      <nav className="bottom-links">
        <span>
          {props.link3 ?? (
            <fragment>
              <span className="bottom-text thq-body-small thq-link">
                DristMine - твой сервер!
              </span>
            </fragment>
          )}
        </span>
      </nav>
    </header>
  )
}

Bottom.defaultProps = {
  rootClassName: '',
  imageAlt1: 'image',
  link3: undefined,
}

Bottom.propTypes = {
  rootClassName: PropTypes.string,
  imageAlt1: PropTypes.string,
  link3: PropTypes.element,
}

export default Bottom
