import React from 'react'

import PropTypes from 'prop-types'

import './title.css'

const Title = (props) => {
  return (
    <div
      className={`title-header26 thq-section-padding ${props.rootClassName} `}
    >
      <div className="title-container"></div>
      <div className="title-content">
        <h1>
          {props.heading1 ?? (
            <fragment>
              <span className="title-text5">
                <span className="title-text6">DristMine</span>
                <br></br>
                <span>Честно, бесплатно, навсегда!</span>
              </span>
            </fragment>
          )}
        </h1>
        <h1>
          {props.heading11 ?? (
            <fragment>
              <span className="title-text3">
                Окунись в нaстоящий дружеский сервер. Без привилегий и лишних
                дополнений.
              </span>
            </fragment>
          )}
        </h1>
        <a href={props.textUrl1} className="title-link">
          <p className="title-text2">
            {props.content1 ?? (
              <fragment>
                <span className="title-text4">Начать игру</span>
              </fragment>
            )}
          </p>
        </a>
      </div>
      <div className="title-container1"></div>
    </div>
  )
}

Title.defaultProps = {
  heading11: undefined,
  content1: undefined,
  rootClassName: '',
  heading1: undefined,
  textUrl1: 'https://www.teleporthq.io',
}

Title.propTypes = {
  heading11: PropTypes.element,
  content1: PropTypes.element,
  rootClassName: PropTypes.string,
  heading1: PropTypes.element,
  textUrl1: PropTypes.string,
}

export default Title
