import React from 'react'

import PropTypes from 'prop-types'

import './join.css'

const Join = (props) => {
  return (
    <div
      id={props.joinId}
      className={`join-header26 thq-section-padding ${props.rootClassName} `}
    >
      <div className="join-container"></div>
      <div className="join-container01">
        <div className="join-container02">
          <span>
            {props.text3 ?? (
              <fragment>
                <span className="join-text12">0 рублей</span>
              </fragment>
            )}
          </span>
          <span>
            {props.text4 ?? (
              <fragment>
                <span className="join-text15">Вечный доступ</span>
              </fragment>
            )}
          </span>
          <a
            href="https://vk.com/im?sel=-215511523"
            target="_blank"
            rel="noreferrer noopener"
            className="join-link"
          >
            {props.text5 ?? (
              <fragment>
                <span className="join-text13 thq-button-filled thq-button-animated">
                  Получить проходку
                </span>
              </fragment>
            )}
          </a>
        </div>
        <div className="join-container03">
          <div className="join-container04">
            <img
              alt={props.imageAlt12}
              src={props.imageSrc12}
              className="join-image"
            />
            <div className="join-container05">
              <div className="join-container06">
                <span>
                  {props.text6 ?? (
                    <fragment>
                      <span className="join-text16">Честно</span>
                    </fragment>
                  )}
                </span>
                <span className="join-text03">
                  {props.text7 ?? (
                    <fragment>
                      <span className="join-text14">
                        Прозрачная политика, уважение от администрации,
                        отсутствие тупых запретов
                      </span>
                    </fragment>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="join-container07">
            <img
              alt={props.imageAlt13}
              src={props.imageSrc13}
              className="join-image1"
            />
            <div className="join-container08">
              <div className="join-container09">
                <span>
                  {props.text8 ?? (
                    <fragment>
                      <span className="join-text11">Бесплатно</span>
                    </fragment>
                  )}
                </span>
                <span className="join-text05">
                  {props.text9 ?? (
                    <fragment>
                      <span className="join-text08">
                        Наша цель - создать идеальный сервер, а сервер с донатом
                        по определению хуже бесплатного
                      </span>
                    </fragment>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="join-container10">
            <img
              alt={props.imageAlt11}
              src={props.imageSrc11}
              className="join-image2"
            />
            <div className="join-container11">
              <div className="join-container12">
                <span>
                  {props.text10 ?? (
                    <fragment>
                      <span className="join-text09">Навсегда</span>
                    </fragment>
                  )}
                </span>
                <span className="join-text07">
                  {props.text11 ?? (
                    <fragment>
                      <span className="join-text10">
                        За 2 года существования сервера, он обрел доверие от
                        более, чем 1000 уникальных игроков. Это только начало!
                      </span>
                    </fragment>
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="join-container13"></div>
    </div>
  )
}

Join.defaultProps = {
  rootClassName: '',
  imageSrc11: '/all_inclusive_96dp_ffffff_fill0_wght400_grad0_opsz48-200h.png',
  text9: undefined,
  text10: undefined,
  text11: undefined,
  imageAlt13: 'image',
  imageAlt11: 'image',
  imageSrc12: '/recommend_96dp_ffffff_fill0_wght400_grad0_opsz48-200h.png',
  text8: undefined,
  joinId: '',
  imageSrc13: '/money_off_96dp_ffffff_fill0_wght400_grad0_opsz48-200h.png',
  imageAlt12: 'image',
  text3: undefined,
  text5: undefined,
  text7: undefined,
  text4: undefined,
  text6: undefined,
}

Join.propTypes = {
  rootClassName: PropTypes.string,
  imageSrc11: PropTypes.string,
  text9: PropTypes.element,
  text10: PropTypes.element,
  text11: PropTypes.element,
  imageAlt13: PropTypes.string,
  imageAlt11: PropTypes.string,
  imageSrc12: PropTypes.string,
  text8: PropTypes.element,
  joinId: PropTypes.string,
  imageSrc13: PropTypes.string,
  imageAlt12: PropTypes.string,
  text3: PropTypes.element,
  text5: PropTypes.element,
  text7: PropTypes.element,
  text4: PropTypes.element,
  text6: PropTypes.element,
}

export default Join
