import React from 'react'

import { Helmet } from 'react-helmet'

import Bottom from '../components/bottom'
import Title from '../components/title'
import Gamemodes from '../components/gamemodes'
import FAQ from '../components/faq'
import Join from '../components/join'
import './about.css'

const About = (props) => {
  return (
    <div className="about-container">
      <Helmet>
        <title>DristMine</title>
        <meta property="og:title" content="exported project" />
      </Helmet>
      <Bottom
        link3={
          <fragment>
            <span className="about-text thq-body-small thq-link">
              DristMine - твой сервер!
            </span>
          </fragment>
        }
        rootClassName="bottom-root-class-name2"
      ></Bottom>
      <Title
        content1={
          <fragment>
            <span className="about-text01">Начать игру</span>
          </fragment>
        }
        heading1={
          <fragment>
            <span className="about-text02">
              <span className="about-text03">DristMine</span>
              <br></br>
              <span>Честно, бесплатно, навсегда!</span>
            </span>
          </fragment>
        }
        textUrl1="#Join"
        heading11={
          <fragment>
            <span className="about-text06">
              Окунись в нaстоящий дружеский сервер. Без привилегий и лишних
              дополнений.
            </span>
          </fragment>
        }
        rootClassName="title-root-class-name"
      ></Title>
      <Gamemodes
        text9={
          <fragment>
            <span className="about-text07">Режимы игры</span>
          </fragment>
        }
        text10={
          <fragment>
            <span className="about-text08 Subtitle">Classic</span>
          </fragment>
        }
        text11={
          <fragment>
            <span className="about-text09 Subtitle">Creative</span>
          </fragment>
        }
        text12={
          <fragment>
            <span className="about-text10 Subtitle">Mordov Game Server</span>
          </fragment>
        }
        text13={
          <fragment>
            <span className="about-text11 Subtitle">Missile Wars</span>
          </fragment>
        }
        text14={
          <fragment>
            <span className="about-text12 Content">
              <span>Выбирай только то, что тебе нравится!</span>
              <span>
                Ванильный сервер с абсолютной настройкой игровых фишек.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </span>
          </fragment>
        }
        text15={
          <fragment>
            <span className="about-text18 Content">
              Ванильный сервер для креативных людей, где любят пранковать
              админа.
            </span>
          </fragment>
        }
        text16={
          <fragment>
            <span className="about-text19 Content">
              Редстоун-миниигра с кучей взрывов. Сломай портал противника любой
              ценой!
            </span>
          </fragment>
        }
        text17={
          <fragment>
            <span className="about-text20 Content">
              Проектируй постройки вместе с друзьями не выходя с сервера!
              Присутствует worldedit.
            </span>
          </fragment>
        }
        text18={
          <fragment>
            <span className="about-text21 Subtitle">Speedrun duels</span>
          </fragment>
        }
        text19={
          <fragment>
            <span className="about-text22">
              Собирай ресурсы для пвп на скорость в рандомных мирах, а затем
              участвуй в сражениях. Удиви соперника своими находками!
            </span>
          </fragment>
        }
        text20={
          <fragment>
            <span className="about-text23">Доступ по заявке:</span>
          </fragment>
        }
        text21={
          <fragment>
            <span className="about-text24">Доступ по заявке:</span>
          </fragment>
        }
        rootClassName="gamemodes-root-class-name2"
      ></Gamemodes>
      <FAQ
        text={
          <fragment>
            <span className="about-text25">@realkomander</span>
          </fragment>
        }
        text1={
          <fragment>
            <span className="about-text26">
              <span>@brat_frank</span>
              <br></br>
            </span>
          </fragment>
        }
        text2={
          <fragment>
            <span className="about-text29">@RealKomander</span>
          </fragment>
        }
        text3={
          <fragment>
            <span className="about-text30">Официальный дискорд</span>
          </fragment>
        }
        text4={
          <fragment>
            <span className="about-text31">
              Главное место сбора всего комьюнити
            </span>
          </fragment>
        }
        text5={
          <fragment>
            <span className="about-text32">Паблик ВК</span>
          </fragment>
        }
        text6={
          <fragment>
            <span className="about-text33">
              <span className="about-text34">
                Здесь как и в дискорде можно подать заявку
              </span>
              <br className="about-text35"></br>
              <br className="about-text36"></br>
              <span className="about-text37">
                Группа нуждается в редакторах, писать админу
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
            </span>
          </fragment>
        }
        fAQ1Id="FAQ1"
        text31={
          <fragment>
            <span className="about-text39">Официальный дискорд</span>
          </fragment>
        }
        text32={
          <fragment>
            <span className="about-text40">Официальный дискорд</span>
          </fragment>
        }
        text41={
          <fragment>
            <span className="about-text41">
              Главное место сбора всего комьюнити
            </span>
          </fragment>
        }
        text42={
          <fragment>
            <span className="about-text42">
              Главное место сбора всего комьюнити
            </span>
          </fragment>
        }
        content2={
          <fragment>
            <span className="about-text43 thq-body-large">
              Связаться с админом:
            </span>
          </fragment>
        }
        heading1={
          <fragment>
            <span className="about-text44 thq-heading-2">
              Часто задаваемые вопросы
            </span>
          </fragment>
        }
        heading2={
          <fragment>
            <span className="about-text45 thq-heading-2">
              Остались вопросы?
            </span>
          </fragment>
        }
        faq1Question={
          <fragment>
            <span className="about-text46 thq-body-large">
              С какой версии игры можно зайти на сервер?
            </span>
          </fragment>
        }
        faq2Question={
          <fragment>
            <span className="about-text47 thq-body-large">
              Когда вайпы на режимах?
            </span>
          </fragment>
        }
        faq3Question={
          <fragment>
            <span className="about-text48 thq-body-large">Это бесплатно?</span>
          </fragment>
        }
        faq4Question={
          <fragment>
            <span className="about-text49 thq-body-large">
              На сервере есть правила?
            </span>
          </fragment>
        }
        faq5Question={
          <fragment>
            <span className="about-text50 thq-body-large">
              Какие гарантии, что сервер не закроется завтра?
            </span>
          </fragment>
        }
        rootClassName="faq-root-class-name1"
      ></FAQ>
      <Join
        text3={
          <fragment>
            <span className="about-text51">0 рублей</span>
          </fragment>
        }
        text4={
          <fragment>
            <span className="about-text52">Вечный доступ</span>
          </fragment>
        }
        text5={
          <fragment>
            <span className="about-text53 thq-button-filled thq-button-animated">
              Получить проходку
            </span>
          </fragment>
        }
        text6={
          <fragment>
            <span className="about-text54">Честно</span>
          </fragment>
        }
        text7={
          <fragment>
            <span className="about-text55">
              Прозрачная политика, уважение от администрации, отсутствие тупых
              запретов.
            </span>
          </fragment>
        }
        text8={
          <fragment>
            <span className="about-text56">Бесплатно</span>
          </fragment>
        }
        text9={
          <fragment>
            <span className="about-text57">
              Мы не собираем &quot;налоги&quot; с игроков на нужды жирных
              админчиков.
            </span>
          </fragment>
        }
        joinId="Join"
        text10={
          <fragment>
            <span className="about-text58">Навсегда</span>
          </fragment>
        }
        text11={
          <fragment>
            <span className="about-text59">
              За 2 года существования сервера, он обрел доверие 1000+ уникальных
              игроков. Это только начало!
            </span>
          </fragment>
        }
        rootClassName="join-root-class-name1"
      ></Join>
      <Bottom
        link3={
          <fragment>
            <span className="about-text60 thq-body-small thq-link">
              DristMine - твой сервер!
            </span>
          </fragment>
        }
        rootClassName="bottom-root-class-name1"
      ></Bottom>
    </div>
  )
}

export default About
