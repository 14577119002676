import React, { useState } from 'react'

import PropTypes from 'prop-types'

import './faq.css'

const FAQ = (props) => {
  const [faq3Visible, setFaq3Visible] = useState(false)
  const [faq4Visible, setFaq4Visible] = useState(false)
  const [faq2Visible, setFaq2Visible] = useState(false)
  const [faq5Visible, setFaq5Visible] = useState(false)
  const [faq1Visible, setFaq1Visible] = useState(false)
  return (
    <div
      id={props.fAQ1Id}
      className={`faq-faq8 thq-section-padding ${props.rootClassName} `}
    >
      <div className="faq-max-width thq-section-max-width">
        <div className="faq-container thq-flex-column">
          <div className="faq-section-title thq-flex-column thq-section-max-width">
            <div className="faq-content">
              <h2>
                {props.heading1 ?? (
                  <fragment>
                    <h2 className="faq-text29 thq-heading-2">
                      Часто задаваемые вопросы
                    </h2>
                  </fragment>
                )}
              </h2>
            </div>
          </div>
          <div className="faq-list thq-flex-column thq-section-max-width">
            <div className="faq-divider thq-divider-horizontal"></div>
            <div className="faq-faq1">
              <div
                onClick={() => setFaq1Visible(!faq1Visible)}
                className="faq-trigger"
              >
                <p>
                  {props.faq1Question ?? (
                    <fragment>
                      <p className="faq-text46 thq-body-large">
                        С какой версии игры можно зайти на сервер?
                      </p>
                    </fragment>
                  )}
                </p>
                <div className="faq-icons-container">
                  {!faq1Visible && (
                    <div>
                      <svg viewBox="0 0 1024 1024" className="faq-icon">
                        <path d="M316 366l196 196 196-196 60 60-256 256-256-256z"></path>
                      </svg>
                    </div>
                  )}
                  {faq1Visible && (
                    <div>
                      <svg viewBox="0 0 1024 1024" className="faq-icon02">
                        <path d="M316 658l-60-60 256-256 256 256-60 60-196-196z"></path>
                      </svg>
                    </div>
                  )}
                </div>
              </div>
              {faq1Visible && (
                <div className="faq-container03">
                  <span className="faq-text01 thq-body-small">
                    <span>
                      Сервер поддерживает java 1.18-1.21 и bedrock 1.21+. Играть
                      можно в том числе и с пиратской java версии игры. При этом
                      игроки с лицензионной версией авторизуются автоматически
                      (без паролей)!
                    </span>
                    <br></br>
                    <span>
                      Для игры с пиратки убедись, что твой ник не занят
                      лицензионным игроком. Для этого, твой ник должен
                      отображаться как свободный на 
                    </span>
                    <a
                      href="https://namemc.com/"
                      target="_blank"
                      rel="noreferrer noopener"
                      className="faq-link"
                    >
                      nameMC
                    </a>
                    <span>.</span>
                  </span>
                </div>
              )}
            </div>
            <div className="faq-divider1 thq-divider-horizontal"></div>
            <div className="faq-faq2">
              <div
                onClick={() => setFaq2Visible(!faq2Visible)}
                className="faq-trigger1"
              >
                <p>
                  {props.faq2Question ?? (
                    <fragment>
                      <p className="faq-text22 thq-body-large">
                        Когда вайпы на режимах?
                      </p>
                    </fragment>
                  )}
                </p>
                <div className="faq-icons-container1">
                  {!faq2Visible && (
                    <div>
                      <svg viewBox="0 0 1024 1024" className="faq-icon04">
                        <path d="M316 366l196 196 196-196 60 60-256 256-256-256z"></path>
                      </svg>
                    </div>
                  )}
                  {faq2Visible && (
                    <div>
                      <svg viewBox="0 0 1024 1024" className="faq-icon06">
                        <path d="M316 658l-60-60 256-256 256 256-60 60-196-196z"></path>
                      </svg>
                    </div>
                  )}
                </div>
              </div>
              {faq2Visible && (
                <div className="faq-container06">
                  <span className="faq-text06 thq-body-small">
                    На режиме &quot;Classic&quot; вайпов не бывает; на остальных
                    режимах об изменениях предупреждаем заблаговременно.
                  </span>
                </div>
              )}
            </div>
            <div className="faq-divider2 thq-divider-horizontal"></div>
            <div className="faq-faq3">
              <div
                onClick={() => setFaq3Visible(!faq3Visible)}
                className="faq-trigger2"
              >
                <p>
                  {props.faq3Question ?? (
                    <fragment>
                      <p className="faq-text44 thq-body-large">
                        Это бесплатно?
                      </p>
                    </fragment>
                  )}
                </p>
                <div className="faq-icons-container2">
                  {!faq3Visible && (
                    <div>
                      <svg viewBox="0 0 1024 1024" className="faq-icon08">
                        <path d="M316 366l196 196 196-196 60 60-256 256-256-256z"></path>
                      </svg>
                    </div>
                  )}
                  {faq3Visible && (
                    <div>
                      <svg viewBox="0 0 1024 1024" className="faq-icon10">
                        <path d="M316 658l-60-60 256-256 256 256-60 60-196-196z"></path>
                      </svg>
                    </div>
                  )}
                </div>
              </div>
              {faq3Visible && (
                <div className="faq-container09">
                  <span className="faq-text07 thq-body-small">
                    Сервер полностью бесплатный, задонатить физически
                    невозможно. Помочь серверу можно напрямую: если ты шаришь в
                    разработке/дизайне/создании контента/проведении ивентов и
                    желаешь помочь в развитии, напиши админу!
                  </span>
                </div>
              )}
            </div>
            <div className="faq-divider3 thq-divider-horizontal"></div>
            <div className="faq-faq4">
              <div
                onClick={() => setFaq4Visible(!faq4Visible)}
                className="faq-trigger3"
              >
                <p>
                  {props.faq4Question ?? (
                    <fragment>
                      <p className="faq-text35 thq-body-large">
                        На сервере есть правила?
                      </p>
                    </fragment>
                  )}
                </p>
                <div className="faq-icons-container3">
                  {!faq4Visible && (
                    <div>
                      <svg viewBox="0 0 1024 1024" className="faq-icon12">
                        <path d="M316 366l196 196 196-196 60 60-256 256-256-256z"></path>
                      </svg>
                    </div>
                  )}
                  {faq4Visible && (
                    <div>
                      <svg viewBox="0 0 1024 1024" className="faq-icon14">
                        <path d="M316 658l-60-60 256-256 256 256-60 60-196-196z"></path>
                      </svg>
                    </div>
                  )}
                </div>
              </div>
              {faq4Visible && (
                <div className="faq-container12">
                  <span className="faq-text08 thq-body-small">
                    <span>
                      Главные правила сервера - веди себя адекватно, не читери и
                      не грифери. Соблюдая их, ты гарантируешь свою безопасность
                      и отсутствие претензий от окружающих. Если же хочется
                      изучить правила в деталях, их можно посмотреть на
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <a
                      href="https://dm-and-mgs.gitbook.io/dm-and-mgs-wiki"
                      target="_blank"
                      rel="noreferrer noopener"
                      className="faq-link1"
                    >
                      wiki
                    </a>
                    <span>
                      .
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </div>
              )}
            </div>
            <div className="faq-divider4 thq-divider-horizontal"></div>
            <div className="faq-faq5">
              <div
                onClick={() => setFaq5Visible(!faq5Visible)}
                className="faq-trigger4"
              >
                <p>
                  {props.faq5Question ?? (
                    <fragment>
                      <p className="faq-text34 thq-body-large">
                        Какие гарантии, что сервер не закроется завтра?
                      </p>
                    </fragment>
                  )}
                </p>
                <div className="faq-icons-container4">
                  {!faq5Visible && (
                    <div>
                      <svg viewBox="0 0 1024 1024" className="faq-icon16">
                        <path d="M316 366l196 196 196-196 60 60-256 256-256-256z"></path>
                      </svg>
                    </div>
                  )}
                  {faq5Visible && (
                    <div>
                      <svg viewBox="0 0 1024 1024" className="faq-icon18">
                        <path d="M316 658l-60-60 256-256 256 256-60 60-196-196z"></path>
                      </svg>
                    </div>
                  )}
                </div>
              </div>
              {faq5Visible && (
                <div className="faq-container15">
                  <span className="faq-text12 thq-body-small">
                    Сервер существует с 22.02.2022 (красивая дата 😍). Начиная с
                    31.07.2022, Дристмайн находится под управлением текущего
                    админа, целью которого является содержать его до конца своей
                    жизни. При этом затраты на сервер минимальны: благодаря
                    домашнему хостингу на ryzen 7 3700x и 96 гб ddr4, сервер с
                    легкостью может выдержать любые нагрузки, с запасом на
                    долгие годы вперед без ежемесячных переплат.
                  </span>
                </div>
              )}
            </div>
            <div className="faq-divider5 thq-divider-horizontal"></div>
          </div>
        </div>
        <div className="faq-content1 thq-flex-column thq-section-max-width">
          <h2>
            {props.heading2 ?? (
              <fragment>
                <h2 className="faq-text30 thq-heading-2">Остались вопросы?</h2>
              </fragment>
            )}
          </h2>
          <p>
            {props.content2 ?? (
              <fragment>
                <p className="faq-text45 thq-body-large">
                  Связаться с админом напрямую:
                </p>
              </fragment>
            )}
          </p>
          <div className="faq-container16">
            <div className="faq-container17">
              <img
                alt={props.imageAlt2}
                src="/untitled-1-200h.png"
                className="faq-image"
              />
              <span>
                {props.text ?? (
                  <fragment>
                    <span className="faq-text33">@realkomander</span>
                  </fragment>
                )}
              </span>
            </div>
            <a
              href="https://vk.com/brat_frank"
              target="_blank"
              rel="noreferrer noopener"
              className="faq-link2"
            >
              <div className="faq-container18">
                <img
                  alt={props.imageAlt3}
                  src="/vk_compact_logo_(2021-present).svg-200h.png"
                  className="faq-image1"
                />
                <span>
                  {props.text1 ?? (
                    <fragment>
                      <span className="faq-text38">
                        <span>@brat_frank</span>
                        <br></br>
                      </span>
                    </fragment>
                  )}
                </span>
              </div>
            </a>
            <a
              href="https://t.me/RealKomander"
              target="_blank"
              rel="noreferrer noopener"
              className="faq-link3"
            >
              <div className="faq-container19">
                <img
                  alt={props.imageAlt1}
                  src="/telegram_2019_logo.svg-200h.png"
                  className="faq-image2"
                />
                <span>
                  {props.text2 ?? (
                    <fragment>
                      <span className="faq-text32">@RealKomander</span>
                    </fragment>
                  )}
                </span>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className="faq-container20">
        <a
          href="https://discord.gg/Wpes3nXm4Q"
          target="_blank"
          rel="noreferrer noopener"
          className="faq-link4"
        >
          <div className="faq-container21">
            <div className="faq-container22">
              <img
                alt={props.imageAlt4}
                src={props.imageSrc4}
                className="faq-image3"
              />
              <img
                alt={props.imageAlt5}
                src={props.imageSrc5}
                className="faq-image4"
              />
            </div>
            <div className="faq-container23">
              <span className="faq-text18">
                {props.text3 ?? (
                  <fragment>
                    <span className="faq-text47">Официальный дискорд</span>
                  </fragment>
                )}
              </span>
              <span className="faq-text19">
                {props.text4 ?? (
                  <fragment>
                    <span className="faq-text36">
                      Главное место сбора всего комьюнити
                    </span>
                  </fragment>
                )}
              </span>
            </div>
          </div>
        </a>
        <a
          href="https://vk.com/im?sel=-215511523"
          target="_blank"
          rel="noreferrer noopener"
          className="faq-link5"
        >
          <div className="faq-container24">
            <div className="faq-container25">
              <img
                alt={props.imageAlt6}
                src={props.imageSrc6}
                className="faq-image5"
              />
              <img
                alt={props.imageAlt7}
                src={props.imageSrc7}
                className="faq-image6"
              />
            </div>
            <div className="faq-container26">
              <span className="faq-text20">
                {props.text5 ?? (
                  <fragment>
                    <span className="faq-text43">Паблик ВК</span>
                  </fragment>
                )}
              </span>
              <span className="faq-text21">
                {props.text6 ?? (
                  <fragment>
                    <span className="faq-text23">
                      <span className="faq-text24">
                        Здесь как и в дискорде можно подать заявку
                      </span>
                      <br className="faq-text25"></br>
                      <br className="faq-text26"></br>
                      <span className="faq-text27">
                        Группа нуждается в редакторах, писать админу
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <br></br>
                    </span>
                  </fragment>
                )}
              </span>
            </div>
          </div>
        </a>
      </div>
    </div>
  )
}

FAQ.defaultProps = {
  faq2Question: undefined,
  text6: undefined,
  heading1: undefined,
  imageAlt2: 'image',
  heading2: undefined,
  text32: undefined,
  imageSrc6: '/vk_compact_logo_(2021-present).svg-200h.png',
  imageAlt51: 'image',
  text2: undefined,
  rootClassName: '',
  imageSrc42: '0b8f7c0c-0eab-4140-bcec-b134961fc65e',
  imageAlt42: 'image',
  imageSrc7: '/link-200h.png',
  imageSrc51: '4d02ae7d-376a-4210-b038-255aa027ea3d',
  text: undefined,
  imageAlt1: 'image',
  faq5Question: undefined,
  imageAlt52: 'image',
  faq4Question: undefined,
  imageAlt3: 'image',
  text4: undefined,
  fAQ1Id: '',
  imageAlt41: 'image',
  text31: undefined,
  imageAlt6: 'image',
  text1: undefined,
  imageSrc4: '/untitled-1-200h.png',
  imageSrc41: '0b8f7c0c-0eab-4140-bcec-b134961fc65e',
  text42: undefined,
  text41: undefined,
  imageAlt5: 'image',
  imageAlt4: 'image',
  imageSrc52: '4d02ae7d-376a-4210-b038-255aa027ea3d',
  text5: undefined,
  imageSrc5: '/link-200h.png',
  faq3Question: undefined,
  content2: undefined,
  faq1Question: undefined,
  imageAlt7: 'image',
  text3: undefined,
}

FAQ.propTypes = {
  faq2Question: PropTypes.element,
  text6: PropTypes.element,
  heading1: PropTypes.element,
  imageAlt2: PropTypes.string,
  heading2: PropTypes.element,
  text32: PropTypes.element,
  imageSrc6: PropTypes.string,
  imageAlt51: PropTypes.string,
  text2: PropTypes.element,
  rootClassName: PropTypes.string,
  imageSrc42: PropTypes.string,
  imageAlt42: PropTypes.string,
  imageSrc7: PropTypes.string,
  imageSrc51: PropTypes.string,
  text: PropTypes.element,
  imageAlt1: PropTypes.string,
  faq5Question: PropTypes.element,
  imageAlt52: PropTypes.string,
  faq4Question: PropTypes.element,
  imageAlt3: PropTypes.string,
  text4: PropTypes.element,
  fAQ1Id: PropTypes.string,
  imageAlt41: PropTypes.string,
  text31: PropTypes.element,
  imageAlt6: PropTypes.string,
  text1: PropTypes.element,
  imageSrc4: PropTypes.string,
  imageSrc41: PropTypes.string,
  text42: PropTypes.element,
  text41: PropTypes.element,
  imageAlt5: PropTypes.string,
  imageAlt4: PropTypes.string,
  imageSrc52: PropTypes.string,
  text5: PropTypes.element,
  imageSrc5: PropTypes.string,
  faq3Question: PropTypes.element,
  content2: PropTypes.element,
  faq1Question: PropTypes.element,
  imageAlt7: PropTypes.string,
  text3: PropTypes.element,
}

export default FAQ
