import React from 'react'

import PropTypes from 'prop-types'

import './gamemodes.css'

const Gamemodes = (props) => {
  return (
    <div
      id={props.gamemodes1Id}
      className={`gamemodes-container thq-section-padding ${props.rootClassName} `}
    >
      <div className="gamemodes-container01">
        <span>
          {props.text9 ?? (
            <fragment>
              <span className="gamemodes-text15">Режимы игры</span>
            </fragment>
          )}
        </span>
      </div>
      <div className="gamemodes-container02">
        <div className="gamemodes-container03">
          <div className="gamemodes-container04">
            <span>
              {props.text10 ?? (
                <fragment>
                  <span className="gamemodes-text22 Subtitle">Classic</span>
                </fragment>
              )}
            </span>
            <div className="gamemodes-container05">
              <span className="gamemodes-text02">
                {props.text14 ?? (
                  <fragment>
                    <span className="gamemodes-text25 Content">
                      <span>Выбирай только то, что тебе нравится! </span>
                      <span>
                        Ванильный сервер с абсолютной настройкой игровых фишек.
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                    </span>
                  </fragment>
                )}
              </span>
              <div className="gamemodes-container06">
                <div className="gamemodes-container07">
                  <span className="gamemodes-text03">
                    {props.text20 ?? (
                      <fragment>
                        <span className="gamemodes-text19">
                          Доступ по заявке:
                        </span>
                      </fragment>
                    )}
                  </span>
                  <a
                    href="https://vk.com/im?sel=-215511523"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="gamemodes-link"
                  >
                    <div className="gamemodes-container08">
                      <img
                        alt={props.imageAlt6}
                        src="/external/link-200h-200h.png"
                        className="gamemodes-image"
                      />
                    </div>
                  </a>
                  <a
                    href="https://discord.com/invite/Wpes3nXm4Q"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="gamemodes-link1"
                  >
                    <div className="gamemodes-container09">
                      <img
                        alt={props.imageAlt5}
                        src={props.imageSrc5}
                        className="gamemodes-image1"
                      />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="gamemodes-container10">
            <span>
              {props.text12 ?? (
                <fragment>
                  <span className="gamemodes-text17 Subtitle">
                    Mordov Game Server
                  </span>
                </fragment>
              )}
            </span>
            <div className="gamemodes-container11">
              <span className="gamemodes-text05">
                {props.text15 ?? (
                  <fragment>
                    <span className="gamemodes-text23 Content">
                      Ванильный сервер для креативных людей, где любят
                      пранковать админа.
                    </span>
                  </fragment>
                )}
              </span>
              <div className="gamemodes-container12">
                <div className="gamemodes-container13">
                  <span className="gamemodes-text06">
                    {props.text21 ?? (
                      <fragment>
                        <span className="gamemodes-text16">
                          Доступ по заявке:
                        </span>
                      </fragment>
                    )}
                  </span>
                </div>
                <a
                  href="https://discord.com/invite/Wpes3nXm4Q"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="gamemodes-link2"
                >
                  <div className="gamemodes-container14">
                    <img
                      alt={props.imageAlt7}
                      src={props.imageSrc7}
                      className="gamemodes-image2"
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="gamemodes-container15">
            <span>
              {props.text13 ?? (
                <fragment>
                  <span className="gamemodes-text21 Subtitle">
                    Missile Wars
                  </span>
                </fragment>
              )}
            </span>
            <div className="gamemodes-container16">
              <span className="gamemodes-text08">
                {props.text16 ?? (
                  <fragment>
                    <span className="gamemodes-text18 Content">
                      Редстоун-миниигра с кучей взрывов. Сломай портал
                      противника любой ценой!
                    </span>
                  </fragment>
                )}
              </span>
            </div>
          </div>
          <div className="gamemodes-container17">
            <span>
              {props.text11 ?? (
                <fragment>
                  <span className="gamemodes-text14 Subtitle">Creative</span>
                </fragment>
              )}
            </span>
            <div className="gamemodes-container18">
              <span className="gamemodes-text10">
                {props.text17 ?? (
                  <fragment>
                    <span className="gamemodes-text20 Content">
                      Проектируй постройки вместе с друзьями не выходя с
                      сервера! Присутствует worldedit.
                    </span>
                  </fragment>
                )}
              </span>
            </div>
          </div>
          <div className="gamemodes-container19">
            <span>
              {props.text18 ?? (
                <fragment>
                  <span className="gamemodes-text13 Subtitle">
                    Speedrun duels
                  </span>
                </fragment>
              )}
            </span>
            <div className="gamemodes-container20">
              <span className="gamemodes-text12">
                {props.text19 ?? (
                  <fragment>
                    <span className="gamemodes-text24">
                      Собирай ресурсы для пвп на скорость в рандомных мирах, а
                      затем участвуй в сражениях. Удиви соперника своими
                      находками!
                    </span>
                  </fragment>
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Gamemodes.defaultProps = {
  text18: undefined,
  gamemodes1Id: '',
  text11: undefined,
  text9: undefined,
  text21: undefined,
  text12: undefined,
  imageAlt7: 'image',
  imageSrc5: '/external/link-200h-200h.png',
  text16: undefined,
  text20: undefined,
  text17: undefined,
  imageSrc7: '/external/link-200h-200h.png',
  text13: undefined,
  text10: undefined,
  imageAlt5: 'image',
  rootClassName: '',
  text15: undefined,
  text19: undefined,
  imageAlt6: 'image',
  text14: undefined,
}

Gamemodes.propTypes = {
  text18: PropTypes.element,
  gamemodes1Id: PropTypes.string,
  text11: PropTypes.element,
  text9: PropTypes.element,
  text21: PropTypes.element,
  text12: PropTypes.element,
  imageAlt7: PropTypes.string,
  imageSrc5: PropTypes.string,
  text16: PropTypes.element,
  text20: PropTypes.element,
  text17: PropTypes.element,
  imageSrc7: PropTypes.string,
  text13: PropTypes.element,
  text10: PropTypes.element,
  imageAlt5: PropTypes.string,
  rootClassName: PropTypes.string,
  text15: PropTypes.element,
  text19: PropTypes.element,
  imageAlt6: PropTypes.string,
  text14: PropTypes.element,
}

export default Gamemodes
